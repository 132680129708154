  .medical-website {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header seaction */
  .header {
    width: 100%;
    height: 70vh;
    background-image: url('https://thumbs.dreamstime.com/b/doctor-medical-assistant-robot-analysis-testing-result-dna-modern-virtual-interface-science-technology-innovative-future-163033177.jpg');
    /* background-image: url('../../assets/heart.png'); */
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    padding: 50px;
    box-sizing: border-box;
  }
  
  .header-content h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .header-content p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .cta-btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    text-decoration: none;
    border: none;
  }
  
  .cta-btn:hover {
    background-color: #0069d9;
  }


  @media (max-width: 768px) {
    .header {
      height: 50vh;
    }
  
    .header-content {
      padding: 30px;
    }
  
    .header-content h1 {
      font-size: 2rem;
    }
  
    .header-content p {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  
    .cta-btn {
      font-size: 1rem;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .header-content {
      padding: 20px;
    }
  
    .header-content h1 {
      font-size: 1.5rem;
    }
  
    .header-content p {
      font-size: 0.8rem;
      margin-bottom: 10px;
    }
  
    .cta-btn {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  /*  */





 

  /* Categories Section */
  .categories {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .categories .home_category_skelton_div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }

  .categories .home_category_skelton_div .categy_single_skeltn {
    background-color: white;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .categories .home_category_skelton_div .categy_text_single_skeltn {
    width: 75px;
    height: 10px;
  }
  
  .categories h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    width: 92%;
    gap: 20px;
  }
  .category_container::-webkit-scrollbar {
    width: 12px;
  }
  .category_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
  }
  .category_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #29166f; 
  }
  
  .category_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .category_item .Icon{
    color: #0069d9;
    font-size: 50px;
  }
  
  .category_item i{
    color: #0069d9;
    font-size: 40px;
  }
  
  .category_item .imageKo_circlw {
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    height: 75px;
    width: 75px;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
  }
  

  .category_item img {
    width: 75%;
    height: 75%;
    object-fit: cover;
  }
  
  .category_item h3 {
    font-size: 10px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    /* .categories {
      margin-top: 250px;
    } */
    /* 
    
    .categories h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    
    .category_container {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      width: 100%;
    }
    
    .category_item {
      width: calc(33.33% - 10px);
      margin-bottom: 20px;
    }
    
    .category_item h3 {
      font-size: 14px;
    } */
  }
  /*  */






  /* Doctor Card */
  .home_doctor_skelton_div {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    width: 100%;
    margin: 0px 70px;
    border-radius: 4px;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
  }

  .doctor-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: all 0.2s ease-in-out;
    width: 275px;
  }
  
  .doctor-card:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .doctor-card-image-container {
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    height: 100%;
    width: 100px;
    aspect-ratio: 1.5;
  }
  
  .doctor-card-image {
    max-width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  
  .doctor-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  
  .doctor-card-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px; 
  }
  
  .doctor-card-specialty {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0;
    opacity: 0.7;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px; 
  }

  /* For screens smaller than 600px */
@media (max-width: 600px) {
  .topdivhead {
    flex-direction: column;
    align-items: center;
  }

  .doctor-card {
    margin: 10px;
    width: 100%;
  }
  
  .doctor-card-image-container {
    border-radius: 10px;
    height: 150px;
    width: 100%;
    aspect-ratio: 3/2;
  }

  .doctor-card-details {
    padding: 10px;
  }

  .doctor-card-name {
    font-size: 20px;
    margin-bottom: 5px;
    width: 100%;
  }

  .doctor-card-specialty {
    font-size: 14px;
    width: 100%;
  }
}

/* For screens larger than 600px */
@media (min-width: 600px) {
  .topdivhead {
    justify-content: flex-start;
  }

  .doctor-card {
    width: 275px;
  }
  
  .doctor-card-image-container {
    width: 100px;
    aspect-ratio: 1.5;
  }

  .doctor-card-name {
    width: 150px;
  }

  .doctor-card-specialty {
    width: 150px;
  }
}
/*  */





/* Services Section */
.services-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 10px auto;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 300px;
  text-align: center;
}

.service i {
  font-size: 3rem;
  margin-bottom: 10px;
}

.service h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service p {
  font-size: 1rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .services-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px auto;
  }

  .service {
    max-width: 100%;
    margin: 20px 0;
  }

  .service i {
    font-size: 2rem;
  }

  .service h2 {
    font-size: 1.2rem;
  }

  .service p {
    font-size: 0.9rem;
  }
}
/*  */




  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  


  .footer_top {
    width: 100%;
    margin-top: 10px;
    background-color: #29166f;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer_second_end{
    width: 100%;
    height: 400px;
    background-color: #1b71a1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 90px 0px 75px;
  }
  .footer_second_end .p_app_name{
    color: white;
    font-size: 45px;
  }
  .footer_second_end .text_light{
    color: white;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 300;
  }
  
  .footer_second_end .footor_inside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    text-decoration: none;
  }
  .footer_second_end .footor_inside .Departments{
    color: white;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .footer_second_end .footor_inside ul {
    text-decoration: none;
  }
  .footer_second_end .footor_inside ul li{
    padding: 10px 0px 10px 0px;
    list-style: none;
  }

  .footer_second_end .footor_inside ul li a{
    color: lightgray;
    text-decoration: none;
  }
  
  
  .footer_end {
    width: 100%;
    background-color: #29166f;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footer_end p{
    color: white;
  }
  
  .footer_end p a{
    color: #1688b0;
  }