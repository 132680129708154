/* Head Dashboard */
.dashboard_Home{
    width: 100%;
    background-color: #eaeef3;
    padding: 20px 0px;
}
 
.dashboard_Home .doctor_card_ho{
  width: 85%;
  align-self: center;
  background-color: white;

  border-radius: 4px;
  margin: 20px 80px;
  padding-top: 20px;
  padding-bottom: 30px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32,32,32,.3);
}

.dashboard_Home .doctor_card_ho .card_inside_head{
  display: flex;
  width: 98%;
  justify-content: space-between;
  padding-left: 20px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .prof_imag img{
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail{
  margin-left: 30px;
  width: 80%;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .justdotdot{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h3{
  color: #4d6c82;
  text-decoration: underline;
  margin-bottom: 5px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h4{
  color: black;
  font-weight: 300;
}

.dashboard_Home .doctor_card_ho .apointment_two_btn{
  display: flex;
  flex-direction: column;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 80%;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail .center_line{
  background-color: lightgray;
  height: 45px;
  width: 1.3px;
  margin-top: 3px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail h3{
  color: black;
  text-decoration: none;
  margin-top: 3px;
  font-size: medium;
}

.apointment_two_btn{
  margin-top: 5px;
  margin-left: 38px;
  /* text-align: center; */
}

.apointment_two_btn .submit{
  padding: 10px 40px;
  border-radius: 8px;
  margin: 5px;
  background-color: #014e78;
  color: white;
  border: 1px solid #014e78;
  font-size: medium;
  transition: 0.3s ease-in-out;
}

.apointment_two_btn #reset{
  background-color: #c02942;
  border-color: #c02942;
}

.apointment_two_btn #submite:hover{
  background-color: #f1f5fc;
  color: #014e78;
}

.apointment_two_btn #reset:hover{
  background-color: #f1f5fc;
  color: #c02942;
}



.dashboard_Home .doctor_card_ho .card_inside_botom{
  width: 35%;
  margin-left: 20px;
  margin-top: 20px;
  padding: 12px 8px 12px 8px;
  border-radius: 6px;
  border: 1px solid #014e78;
}


.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm{
  display: flex;
  margin-bottom: 7px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm .sidebarIcon{
  font-size: large;
  color: black;
  font-weight: 300;
  margin-top: 2px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm h3{
  color: black;
  font-weight: 400;
  margin-left: 6px;
  font-size: 16px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm{
  display: flex;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm .sidebarIcon{
  font-size: large;
  color: black;
  font-weight: 300;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm h3{
  color: gray;
  font-weight: 600;
  margin-left: 6px;
  font-size: 13px;
}


.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h4{
  color: #029278;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h5{
  color: black;
  font-size: 14px;
  font-weight: 400;
}