.main_container_appointment{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px;
  background-color: lightgray;
  border-radius: 10px;
}

.main_container_appointment p {
  color: black;
  font-size: 20px;
  margin: 10px 0px 0px 10px;
  font-weight: bold;
}

.main_container_appointment hr {
  color: black;
  margin: 10px 0px 0px 0px;
}

.main_container_appointment .div_second_main{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.main_container_appointment .appointment_skelton_div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.main_container_appointment .appointment_skelton_div .single_skelton{
  margin: 10px;
}

.card {
  width: 48%;
  margin: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  padding: 10px 0px 10px 0px;
}

.card:hover {
  transform: scale(1.05);
}

.card .imagediv {
  width: 25%;
}

.card .imagediv img {
  border-radius: 10px;
  margin: 8px 0px 0px 11px;
  width: 90%;
  height: 120px;
  object-fit: cover;
}

.card .card-body {
  padding: 0px 0px 0px 10px;
  width: 75%;
}

.card .card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.card .card-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  color: #6c757d;
  margin-bottom: 1rem;

  font-style: italic;
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}

.card .card-text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #6c757d;
}

.card .card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
}

.card .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  font-weight: 500;
}

.card .btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.card .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  font-weight: 500;
}

.card .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}
