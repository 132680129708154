.login_container {
    background-image: url('https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
    /* background-image: url('https://source.unsplash.com/800x600/?doctor'); */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.login_container .login_card {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}
  
.login_container .login_card h2 {
    text-align: center;
    margin-bottom: 30px;
}
  
.login_container .login_card .form_group {
    margin-bottom: 20px;
}
  
.login_container .login_card .form_group .form_control {
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    width: 100%;
}
  
.login_container .login_card .butn {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}
  
.login_container .login_card .butn:hover {
    background-color: #0069d9;
}
  
.login_container .login_card .text_center {
    text-align: center;
}
  
.login_container .login_card .mt_3 {
    margin-top: 1rem;
}
  
.login_container .login_card .mt_3 a {
    color: #007bff;
    text-decoration: none;
}
  
.login_container .login_card .mt_3 a:hover {
    text-decoration: underline;
}



.login_container .login_card .loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
  
.login_container .login_card .loader_container .loader {
    /* border: 10px solid rgba(0, 0, 0, 0.1);
    border-top-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-in-out infinite; */

    border: 16px solid #f3f3f3;
    border-top: 16px solid #1a6aff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}
  
@keyframes spin {
    /* to {
      transform: rotate(360deg);
    } */
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}