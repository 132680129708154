.chat_screen_container {
  display: flex;
  height: 78vh;
  margin: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chat_screen_container .chat_list_container {
  flex: 1;
  background-color: #f7f7f7;
  padding: 20px;
}

.chat_screen_container .chat_list_container .chat_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item:hover,
.chat_screen_container .chat_list_container .chat_list .chat_list_item.active {
  background-color: #e2e2e2;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_image_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details {
  flex: 1;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details h3 {
  font-size: 1.2rem;
  margin: 0;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details .file_pdf_view {
  display: flex;
  gap: 5px;
}
.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details .file_pdf_view .icon{
  color: gray;
  font-size: 17px;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details p,
.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details span {
  font-size: 0.9rem;
  margin: 0;
  color: #808080;
}

.chat_room_container {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.chat_room_no_active_container{
  flex: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}
.chat_room_no_active_container img{
  width: 100%;
  height: 100%;
}

.chat-room-header {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d9d9d9;
}

.chat-room-header-image-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-room-header-details {
  margin-left: 10px;
}

.chat-room-header-details h3 {
  font-size: 1.2rem;
  margin: 0;
}

.chat-room-header-details span {
  font-size: 0.9rem;
  color: #808080;
}


/* Chat room body */
.chat_room_container .chat_room_messages_container {
  flex: 1;
  overflow-y: scroll;
  overflow-y: auto;
  padding: 20px;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  /* float: left; */
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_message_image_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details {
  max-width: 80%;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details p {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details span {
  font-size: 0.7rem;
  color: #808080;
  margin-left: auto;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details .chat_room_message_image_views_zoom img {
  width: 200px;
  height: 200px;
  border-radius: 5%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_pdf_details {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message span {
  font-size: 0.7rem;
  color: #808080;
}

/* .chat-room-message.incoming .chat_room_left_message_details {
  order: 1;
}

.chat-room-message.outgoing .chat_room_left_message_details {
  order: 2;
  text-align: right;
} */


.chat_room_container .chat_room_messages_container .chat_room_right_message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: flex-end;
  width: 80%;
  /* background-color: pink; */
  /* float: right; */
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_message_image_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details  {
  text-align: end;
}
.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details p {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details span {
  font-size: 0.7rem;
  color: #808080;
  margin-left: auto;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details .chat_room_message_image_views_zoom img {
  width: 200px;
  height: 200px;
  border-radius: 5%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_pdf_details {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message span {
  font-size: 0.7rem;
  color: #808080;
}

/* .chat-room-message.incoming .chat_room_right_message_details {
  order: 1;
}

.chat-room-message.outgoing{
  background-color: pink;
  justify-content: end;
}

.chat-room-message.outgoing .chat_room_right_message_details {
  order: 2;
  text-align: right;
} */




.chat-room-input-container {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
}

.chat-room-input-container input {
  width: calc(100% - 80px);
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f5f5f5;
  font-size: 16px;
  font-family: Arial, sans-serif;
  outline: none;
}

.chat-room-input-container button {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #2979FF;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;
}

.chat-room-input-container button:hover {
  background-color: #2962FF;
}





.loader_doctor_list{
  width: 100%;
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader_doctor_list p{
  margin-top: 10px;
}





.custom_file_input {
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
  color: #1a6aff;
  margin-top: 5px;
  font-size: medium;
  transition: 0.3s ease-in-out;
  margin-right: 10px;
}

.custom_file_input input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.profileImage .custom_file_input:hover{
  background-color: #f1f5fc;
  color: #1a6aff;
}