.navbar .nav_menu .nav_item .dropdown_menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.navbar .nav_menu .nav_item .dropdown_menu li {
  background: whitesmoke;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
}

.navbar .nav_menu .nav_item .dropdown_menu li:hover, .navbar .nav_menu .nav_item .dropdown_menu .dropdown_link:hover {
  background: #1a6aff;
  color: white;
}

.navbar .nav_menu .nav_item .dropdown_menu.clicked {
  display: none;
}

.navbar .nav_menu .nav_item .dropdown_menu .dropdown_link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: black;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
