.modalBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modalContainer {
  width:60%;
  height: 400px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .titleCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalContainer .titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .title {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.modalContainer .all_week_day {
  display: flex;
}
.modalContainer .all_week_day::-webkit-scrollbar {
  width: 12px;
}
.modalContainer .all_week_day::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}
.modalContainer .all_week_day::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #eaeef3; 
}

.modalContainer .all_week_day .oneday_ofweek {
  padding: 10px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  border: 1px solid;
  border-color: lightgray;
  border-width: 1.5px;
  margin-left: 8px;
  margin-top: 10px;
  margin-right: 8px;
}
.modalContainer .all_week_day .oneday_ofweek p{
  font-size: 13px;
  color: gray;
}

.modalContainer .all_week_day .oneday_ofweek.active {
  padding: 10px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  border: 1px solid;
  border-color: #29166f;
  border-width: 1.5px;
  margin-left: 8px;
  margin-top: 10px;
  margin-right: 8px;
}
.modalContainer .all_week_day .oneday_ofweek.active p{
  font-size: 13px;
  color: #29166f;
}

.modalContainer .center_line {
  width: 100%;
  background-color: lightgray;
  height: 1.5px;
  margin: 15px 0px 15px 0px;
}

.modalContainer .all_time_slot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modalContainer .all_time_slot .single_time {
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid;
  border-color: lightgray;
  border-width: 1.5px;
  margin-left: 10px;
  padding: 7px 15px 7px 15px;
  margin-top: 10px;
}
.modalContainer .all_time_slot .single_time p {
  font-size: 13px;
  color: gray;
}


.modalContainer .all_time_slot .single_time.active {
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid;
  border-color: #29166f;
  background-color: #29166f;
  border-width: 1.5px;
  margin-left: 10px;
  padding: 7px 15px 7px 15px;
  margin-top: 10px;
}
.modalContainer .all_time_slot .single_time.active p {
  font-size: 13px;
  color: white;
}




.modalContainer .book_btn {
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: #29166f;
  padding: 13px 0px 13px 0px;
  margin-top: 20px;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  width: 35%;
  transition: 0.4s ease-in-out;
}

.modalContainer .book_btn:hover {
  border: 1px solid #29166f;
  background-color: white;
  color: #29166f;
}










/* Modal Input Style */
.modalContainer .patient_first_name{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.modalContainer .patient_first_name .update_width_both_name_email{
  width: 94%;
  display: flex;
  justify-content: space-between;
}

.modalContainer .patient_first_name .update_width_both_name_email .update_width_name{
  width: 49%;
}
.modalContainer .patient_first_name .update_width_both_name_email .update_width_name #name{
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}
.modalContainer .patient_first_name .update_width_both_name_email .update_width_name #email{
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}


.modalContainer .update_second_subject{
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContainer .update_second_subject .updat_width_subject{
  width: 94%;
}
.modalContainer .update_second_subject .updat_width_subject #subject{
  width: 100%;
  border: 0.11px solid gray;
  padding: 12px;
  border-radius: 8px;
}
/*  */