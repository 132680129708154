.modal_detailBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modal_detailBackground .modaldetailContainer {
  width:40%;
  height: 500px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modaldetailContainer .titledetailCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modaldetailContainer .titledetailCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modaldetailContainer .imagemodal {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0px 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer img {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  object-fit: cover;
}

.modaldetailContainer .imagemodal .name_and_address{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modaldetailContainer .imagemodal .name_and_address h3{
  color: black;
}

.modaldetailContainer .imagemodal .name_and_address h4{
  color: gray;
}


.modaldetailContainer .deatiylsmodal {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0px 30px 15px 30px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer .deatiylsmodal .top_in_detail {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.modaldetailContainer .deatiylsmodal .top_in_detail h3{
  color: gray;
  font-size: medium;
}

.modaldetailContainer .paymethodmodal {
  width: 100%;
  background-color: #effbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0px 30px 15px 30px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer .paymethodmodal .top_in_paymethod {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.modaldetailContainer .paymethodmodal .top_in_paymethod h3 {
  color: black;
  font-size: 14px;
}

.modaldetailContainer .paymethodmodal .top_in_paymethod h4 {
  font-size: 14px;
  color: #29166f;
}

.modaldetailContainer .botommodal {
  width: 100%;
  background-color: #29166f;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0px 0px 10px 0px;
  margin-top: 30px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer .botommodal p {
  color: white;
}

.modaldetailContainer .paymethodmodal .top_in_paymethod .icons {
  color: #29166f;
  margin-right: 10px;
}